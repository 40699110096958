footer {
  min-height: 351px;
  background-position: center;
  background-size: cover;
  margin: 100px 0 0 0;
  font-size: 0.875rem;
  position: relative;
  
  .container {
    position: absolute;
    bottom: 0;
    right: 20px;
  }
  
  .nav {
    justify-content: flex-end;
    margin-top: 60px;
    
    .nav-item {
      .nav-link {
        color: #2F2F2F;
        padding: 0 0 0 8px;
        text-decoration: underline;
        
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}