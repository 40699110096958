.Article {
  
  ul {
    padding: 0;
    
    li {
      list-style-type: none;
      padding: 3px 0 0 27px;
      position: relative;
      font-weight: 400;

      &:before {
        content: "";
        font-family: 'Font Awesome 5 Free';
        left: 0;
        position: absolute;
      }
    }
  }
  
  &.start-image {
    .bg {
      height: 186px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 70px 0 0 0;
    }
  }
  
  .container {
    .article-content-linker {
      border-top: 1px solid #dddddd;
      padding-top: 5px;
      margin-top: 50px;
    }
  }
}