h1 {
  font-weight: bold;
  color: $primary;
  padding-bottom: 2rem;
}

h2 {
  padding-top: 10px;
}

h3 {
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.map-canvas {
  width: 100%!important;
}

body {
  background: #fff url('../img/gradient-grey.png') repeat-x;
  color: #4F4F4F;
}

.content {
  min-height: 346px;
  padding-top: 50px;
}

.blockquote {
  blockquote {
    background-color: $primary;
    color: white;
    padding: 15px;
    margin: 0;

    p {
      font-size: 1rem;
      font-style: italic;
      margin: 0;
    }
  }

  p {
    font-size: 12px;
    margin: 5px 0 0 0;
  }
}

.gradient-white-blue {
  border: 2px solid #cee7f4;
  min-height: 300px;
  background: rgb(206,231,243);
  background: -moz-linear-gradient(0deg, rgba(206,231,243,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(206,231,243,1) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(0deg, rgba(206,231,243,1) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cee7f3",endColorstr="#ffffff",GradientType=1);
}

body {
  &#start {
    height: 100vh;
    
    .content {
      padding-top: 20px;
    }
  }
}

.contact-bar {
  position: absolute;
  right: 15px;
  background-color: white;
  padding: 5px 15px;
  font-size: 12px;
  z-index: 9999;

  @include media-breakpoint-down(sm) {
    display: none;
  }
  
  a {
    &:not(:last-of-type) {
      padding-right: 10px;
    }
    
    i {
      margin-right: 2px;
    }
    
    &:hover {
      text-decoration: none;
    }
  }
}

.form-group {
  label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 16px;
  }
}

form {
  .confirm { 
    p {
      color: $primary;
      border: 1px solid $primary;
      padding: 15px;
      border-radius: 5px;
      font-size: 14px;
    }
  }
}