.button {
  a, button, input {
    @extend .btn;
  }

  &.button-primary {
    a, button, input {
      @extend .btn-primary;
      padding: 8px 15px;
      border-radius: 50px;
    }
  }

  &.button-primary-gradient {
    a, button, input {
      @extend .btn-primary;
      background: rgb(50,143,210);
      background: -moz-linear-gradient(0deg, rgba(50,143,210,1) 45%, rgba(143,204,231,1) 100%);
      background: -webkit-linear-gradient(0deg, rgba(50,143,210,1) 45%, rgba(143,204,231,1) 100%);
      background: linear-gradient(0deg, rgba(50,143,210,1) 45%, rgba(143,204,231,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#328fd2",endColorstr="#8fcce7",GradientType=1);
      font-weight: bold;
      text-transform: uppercase;
      padding: 10px 20px;
      border-radius: 100px;
    }
  }

  &.button-default {
    a, button, input {
      @extend .btn-light;
    }
  }

  &.button-block {
    a, button, input {
      @extend .btn-block;
    }
  }
}

.btn {
  &:focus {
    box-shadow: none;
  }
  
  /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
  &.btn-white {
    @include button-variant(#fff, #fff, darken(#fff, 7.5%), darken(#fff, 7.5%), #fff, #fff);
  }
}

li {
  &.display-button {
    a {
      @extend .btn-light;
    }
  }
}