.map-canvas {
  width: 100% !important;
}

$marker-background-color: white;
$marker-color: $primary;
$leaflet-border-color: #c3c3c3;

.custom-leaflet-marker {
  background-color: $marker-background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: $marker-color;
  border-radius: 40px;
  box-shadow: rgb(0 0 0 / 38%) 2px 2px 10px;

  &:after {
    border: 15px solid transparent;
    border-top: 16px solid $marker-background-color;
    bottom: -24px;
    content: "";
    height: 0;
    position: absolute;
    width: 0;
  }

  path {
    fill: $primary;
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 0;

  .leaflet-popup-content {
    font-size: 14px;

    .company-link-infos, .company-link-jobs {
      border-bottom: 0;
      display: block;
      margin: 0;
      padding: 5px 10px 5px 25px;
    }

    .company-link-infos {
      &:before {
        font-family: "Font Awesome 5 Free",sans-serif;
        content: "\f05a";
        color: $primary;
        position: absolute;
        left: 20px;
      }
    }

    .company-link-jobs {
      &:before {
        font-family: "Font Awesome 5 Free",sans-serif;
        content: "\f0b1";
        color: $primary;
        position: absolute;
        left: 20px;
      }
    }

    .links {
      display: flex;
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #c3c3c3;

      .link-item {
        align-items: center;
        display: flex;
        margin: 0 20px 10px 0;

        i {
          color: $marker-color;
          font-size: 20px;
          margin: 0 5px 0 0;
        }
      }
    }

    p {
      border-bottom: 1px solid $leaflet-border-color;
      padding-bottom: 15px;

      strong {
        font-size: 16px;
        color: $primary;
      }
    }

    ul {
      list-style: none;
      padding: 0;
    }
  }
}

.leaflet-popup {
  min-width: 280px;
}

.leaflet-container a.leaflet-popup-close-button {
  font-size: 30px;
  right: -40px;
  color: white;
  top: 0px;
  background-color: $marker-color;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: 8888;
  transition: all .2s ease-in-out;

  &:hover {
    color: white;
    background-color: darken($marker-color, 7%);
  }
}