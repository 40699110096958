header {
  &#top {
    .container {
      position: relative;
    }
    
    .navbar, .navbar-brand {
      padding: 0;
    }
    
    .navbar-nav {

      @include media-breakpoint-down(md) {
        margin-top: 20px;

        .nav-link {
          padding-left: 0;
          padding-right: 0;
        }
        
        .nav-item {
          
          &:not(:last-of-type) {
            border-bottom: 1px solid #dddddd;
          }
          
          &.active, &.trail {
            a {
              &:after {
                display: none;
              }
            }
          }
        }
      }
      
      .nav-item {
        
        &.active, &.trail {
          a {
            color: $primary;
            position: relative;

            &:after {
              border-top: 2px solid $primary;
              content: "";
              height: 2px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: calc(100% - 30px);
            }
          }
        }
      }
      
      .nav-link {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    
  }
}